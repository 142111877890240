import axiosClient from "./customAxios";

//Appel API de la liste des ecoles
export async function getEcoles() {
  return axiosClient
    .get(process.env.VUE_APP_ROOT_API + `ecoles`)
    .then((res) => {
      //console.log({res})
      return res.data.body;
    })
    .catch((e) => {
      //console.log("erreur " + e);
    });
}


// Ajouter/modifier une ecole en BDD via API
export async function addEcole(ecole) {

  var ecoleJSON = JSON.stringify(ecole);
  //console.log(ecoleJSON);
  return axiosClient.post(
    process.env.VUE_APP_ROOT_API + "ecoles",
    ecoleJSON
  )
    .then(function (response) {
      return response;
    })
    .catch(function (erreur) {
      //console.log(erreur);
    });
}

// Delete une ecole en fonction de son ID
export async function deleleteEcole(idecole) {
  return axiosClient.delete(`${process.env.VUE_APP_ROOT_API}ecoles/${idecole}`)
}
