<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addSourceChasse">
      Ajouter source chasse
    </v-btn>
    <v-dialog v-model="dialogAddSourceChasse" max-width="700px" @click:outside="closeEditSourceChasse()">
      <v-card>
        <v-card-title>
          <span class="headline">Source Chasse</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifSourceChasse" label="Source chasse*"
                    :rules="[(v) => !!v || 'La source chasse est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditSourceChasse">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditSourceChasse">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditSourceChasse" max-width="700px" @click:outside="closeEditSourceChasse">
      <v-card>
        <v-card-title>
          <span class="headline">Sourche Chasse</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedSourceChasse" label="Profil*"
                    :rules="[(v) => !!v || 'Sourche Chasse est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditSourceChasse">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editSourceChasse">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelSourceChasse" max-width="665px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer cette source chasse
          ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelSourceChasse">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteSourceChasseConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers_source_chasse" :items="$store.getters.champs.sources_chasse" :items-per-page="5"
      class="elevation-1" :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditSourceChasseDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delSourceChasse(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addSourceChasseAPI } from "../../api/admin/source_chasse/addsources_chasse";
import { updatedesistementAPI } from "../../api/admin/source_chasse/putsources_chasse"
import { delSourceChasseAPI } from "../../api/admin/source_chasse/delsources_chasse";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_source_chasse: [],
    dialogAddSourceChasse: false,
    dialogDelSourceChasse: false,
    valid: true,
    item: [],
    editedItem: [],
    modifSourceChasse: [],
    statusCode: 0,
    sourcechasselList: [],
    dialogEditSourceChasse: false,
    updatedSourceChasse: "",

  }),
  created() {
    this.initialize();
    this.sourcechasselList = this.$store.getters.champs.sources_chasse;

  },
  methods: {
    initialize() {
      this.headers_source_chasse = [
        {
          text: "Sources chasse",
          value: "source_chasse",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addSourceChasse() {
      this.dialogAddSourceChasse = true;
      this.modifSourceChasse = "";
    },
    async saveEditSourceChasse() {
      await addSourceChasseAPI(
        this.modifSourceChasse
      );
      this.closeEditSourceChasse();
      this.sourcechasselList.push(this.modifSourceChasse);
    },

    async editSourceChasse() {
      const data = {
        body: JSON.stringify({
          source_chasse: this.modifSourceChasse,
          updated_source_chasse: this.updatedSourceChasse
        })
      }

      await updatedesistementAPI(data);

      // Supprimer l'ancien statut de la liste
      const index = this.sourcechasselList.indexOf(this.modifSourceChasse);
      if (index !== -1) {
        this.sourcechasselList.splice(index, 1);
      }

      // Ajouter le statut mis à jour dans la liste
      this.sourcechasselList.push(this.updatedSourceChasse);

      this.closeEditSourceChasse(); // Fermer le dialogue ici
    },
    openEditSourceChasseDialog(item) {
      this.modifSourceChasse = item;
      this.updatedSourceChasse = item;
      this.dialogEditSourceChasse = true;
    },

    closeDelSourceChasse() {
      this.dialogDelSourceChasse = false;
    },

    closeEditSourceChasse() {
      this.dialogAddSourceChasse = false;
      this.dialogEditSourceChasse = false
      this.$refs.form.resetValidation();
    },
    delSourceChasse(item) {
      this.modifSourceChasse = item;
      this.dialogDelSourceChasse = true;
    },


    deleteSourceChasseConfirm() {
      delSourceChasseAPI(this.modifSourceChasse)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Statut supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.sourcechasselList.indexOf(this.modifSourceChasse);
          if (index > -1) {
            this.sourcechasselList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelSourceChasse();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },
    closeDelSourceChasse() {
      this.dialogDelSourceChasse = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
