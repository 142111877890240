<template>
  <div>

    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addDesistement">
      Ajouter Desistement
    </v-btn>

    <v-dialog v-model="dialogAddDesistement" max-width="700px" @click:outside="closeEditDesistement()">
      <v-card>
        <v-card-title>
          <span class="headline">Désistement</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifDesistement" label="Désistement*"
                    :rules="[(v) => !!v || 'Le desistement est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4" clearable></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDesistement">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditDesistement">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditDesistement" max-width="700px" @click:outside="closeEditDesistement">
      <v-card>
        <v-card-title>
          <span class="headline">Desistement</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedDesistement" label="Profil*"
                    :rules="[(v) => !!v || 'Le Desistement est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDesistement">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editDesistement">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelDesistement" max-width="610px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce desistement ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelDesistement">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteDesistementConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_desistement" :items="$store.getters.champs.desistement" :items-per-page="5"
      class="elevation-1" :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditDesistementDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delDesistement(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addDesistementAPI } from "../../api/admin/desistement/adddesistements";
import { updatedesistementAPI } from "../../api/admin/desistement/putdesistements";
import { delDesistementAPI } from "../../api/admin/desistement/deldesistements";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_desistement: [],
    dialogAddDesistement: false,
    dialogDelDesistement: false,
    valid: true,
    item: [],
    editedItem: [],
    modifDesistement: [],
    statusCode: 0,
    desistementlList: [],
    dialogEditDesistement: false,
    updatedDesistement: "",

  }),
  created() {
    this.initialize();
    this.desistementlList = this.$store.getters.champs.desistement;

  },
  methods: {
    initialize() {
      this.headers_desistement = [
        {
          text: "Desistements",
          value: "desistement",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addDesistement() {
      this.dialogAddDesistement = true;
      this.modifDesistement = "";
    },
    async saveEditDesistement() {
      await addDesistementAPI(
        this.modifDesistement
      );
      this.closeEditDesistement();
      this.desistementlList.push(this.modifDesistement);
    },

    async editDesistement() {
      const data = {
        body: JSON.stringify({
          desistement: this.modifDesistement,
          updated_desistement: this.updatedDesistement
        })
      }

      await updatedesistementAPI(data);

      // Supprimer l'ancien statut de la liste
      const index = this.desistementlList.indexOf(this.modifDesistement);
      if (index !== -1) {
        this.desistementlList.splice(index, 1);
      }

      // Ajouter le statut mis à jour dans la liste
      this.desistementlList.push(this.updatedDesistement);

      this.closeEditDesistement(); // Fermer le dialogue ici
    },

    openEditDesistementDialog(item) {
      this.modifDesistement = item;
      this.updatedDesistement = item;
      this.dialogEditDesistement = true;
    },
    closeEditDesistement() {
      this.dialogAddDesistement = false;
      this.dialogEditDesistement = false
      this.$refs.form.resetValidation();
    },
    delDesistement(item) {
      this.modifDesistement = item;
      this.dialogDelDesistement = true;
    },

    deleteDesistementConfirm() {
      delDesistementAPI(this.modifDesistement)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Statut supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.desistementlList.indexOf(this.modifDesistement);
          if (index > -1) {
            this.desistementlList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelDesistement();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },
    closeDelDesistement() {
      this.dialogDelDesistement = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
