import axiosClient from "../../customAxios";

//Delete un profil en fonction de son ID
export async function delProfilAPI(profil) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      body: JSON.stringify({ "profil": profil })
    }
    
  };

  try {
    const response = await axiosClient.delete(
      process.env.VUE_APP_ROOT_API + "champs/champ/" ,
      options
    );
    
    //console.log('____RESP_____', response);
    return response;
  } catch (error) {
    //console.log(error);
    return error;
  }
}
