<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="openAddProfilDialog">Ajouter
      emploi</v-btn>

    <v-dialog v-model="dialogAddProfil" max-width="700px" @click:outside="closeAddProfilDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Emploi</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifProfil" label="Emlpoi*" :rules="[(v) => !!v || 'Emploi est obligatoire']"
                    required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddProfilDialog">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditProfil">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditProfil" max-width="700px" @click:outside="closeEditProfilDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Emlpoi</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedProfil" label="Profil*"
                    :rules="[(v) => !!v || 'Le profil est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditProfilDialog">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editProfil">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelProfil" max-width="535px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer cet emploi ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelProfil">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteProfilConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_profil" :items="profils" class="elevation-1" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditProfilDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="openDelProfilDialog(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import { addProfilAPI } from "../../api/admin/profil/addprofils";
import { updateProfilAPI } from "../../api/admin/profil/putprofil";
import { delProfilAPI } from "../../api/admin/profil/delprofils";
import { getModifChampsAPI } from "../../api/admin/getchamps";
import DelSnackbar from "../snackbar/DelSnackbar.vue";

export default {
  components: { DelSnackbar },
  data() {
    return {
      headers_profil: [
        {
          text: "Emploi",
          value: "profil",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      dialogAddProfil: false,
      dialogDelProfil: false,
      valid: true,
      modifProfil: "",
      statusCode: 0,
      profils: [],
      dialogEditProfil: false,
      updatedProfil: "",
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.profils = this.$store.getters.champs.profils;
    },

    openAddProfilDialog() {
      this.modifProfil = "";
      this.dialogAddProfil = true;
    },

    async saveEditProfil() {
      await addProfilAPI(this.modifProfil);
      this.closeAddProfilDialog();
      this.profils.push(this.modifProfil);
    },

    async editProfil() {
      const data = {
        body: JSON.stringify({
          profil: this.modifProfil,
          updated_profil: this.updatedProfil
        })
      }

      await updateProfilAPI(data, this.$store.getters.access_token);

      // Supprimer l'ancien profil du tableau
      const index = this.profils.indexOf(this.modifProfil);
      if (index !== -1) {
        this.profils.splice(index, 1);
      }

      // Ajouter le profil mis à jour dans le tableau
      this.profils.push(this.updatedProfil);

      this.closeEditProfilDialog();
    },

    closeAddProfilDialog() {
      this.dialogAddProfil = false;
      this.$refs.form.resetValidation();
    },

    closeEditProfilDialog() {
      this.dialogEditProfil = false;
      this.$refs.form.resetValidation();
    },

    openEditProfilDialog(item) {
      this.modifProfil = item;
      this.updatedProfil = item;
      this.dialogEditProfil = true;
    },

    openDelProfilDialog(item) {
      this.modifProfil = item;
      this.dialogDelProfil = true;
    },

    async deleteProfilConfirm() {
      this.statusCode = await delProfilAPI(this.modifProfil);
      const index = this.profils.indexOf(this.modifProfil);
      if (index !== -1) {
        this.profils.splice(index, 1);
      }
      this.dialogDelProfil = false;
    },

    closeDelProfil() {
      this.dialogDelProfil = false;
    },

    async refreshdata() {
      this.$store.commit("SET_CURRENT_CHAMPS", await getModifChampsAPI());
      this.profils = this.$store.getters.champs.profils;
    },
  },
};
</script>
