<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="openAddProfilFCTDialog">Ajouter emploi
      FCT</v-btn>

    <v-dialog v-model="dialogAddProfilFCT" max-width="700px" @click:outside="closeAddProfilFCTDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Emlpoi FCT</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifProfilFCT" label="Emlpoi*"
                    :rules="[(v) => !!v || 'Emlpoi FCT est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddProfilFCTDialog">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditProfilFCT">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditProfilFCT" max-width="700px" @click:outside="closeEditProfilFCTDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Emploi FCT</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedProfilFCT" label="Profil FCT *"
                    :rules="[(v) => !!v || 'Le profil FCT est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditProfilFCTDialog">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editProfilFCT">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelProfilFCT" max-width="535px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce profil ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelProfilFCT">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteProfilFCTConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_profilFCT" :items="profilsFCT" class="elevation-1" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditProfilFCTDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="openDelProfilFCTDialog(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import { addProfilFCTAPI } from "../../api/admin/profilFCT/addprofilsFCT";
import { updateProfilFCTAPI } from "../../api/admin/profilFCT/putprofilFCT";
import { delProfilFCTAPI } from "../../api/admin/profilFCT/delprofilsFCT";
import { getModifChampsAPI } from "../../api/admin/getchamps";
import DelSnackbar from "../snackbar/DelSnackbar.vue";

export default {
  components: { DelSnackbar },
  data() {
    return {
      headers_profilFCT: [
        {
          text: "Profils FCT",
          value: "profil FCT",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      dialogAddProfilFCT: false,
      dialogDelProfilFCT: false,
      valid: true,
      modifProfilFCT: "",
      statusCode: 0,
      profilsFCT: [],
      dialogEditProfilFCT: false,
      updatedProfilFCT: "",
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.profilsFCT = this.$store.getters.champs.profilsFCT;
    },

    openAddProfilFCTDialog() {
      this.modifProfilFCT = "";
      this.dialogAddProfilFCT = true;
    },

    async saveEditProfilFCT() {
      await addProfilFCTAPI(this.modifProfilFCT);
      this.closeAddProfilFCTDialog();
      this.profilsFCT.push(this.modifProfilFCT);
    },

    async editProfilFCT() {
      const data = {
        body: JSON.stringify({
          profilFCT: this.modifProfilFCT,
          updated_profilFCT: this.updatedProfilFCT
        })
      }

      await updateProfilFCTAPI(data);

      // Supprimer l'ancien profil du tableau
      const index = this.profilsFCT.indexOf(this.modifProfilFCT);
      if (index !== -1) {
        this.profilsFCT.splice(index, 1);
      }

      // Ajouter le profil mis à jour dans le tableau
      this.profilsFCT.push(this.updatedProfilFCT);

      this.closeEditProfilFCTDialog();
    },

    closeAddProfilFCTDialog() {
      this.dialogAddProfilFCT = false;
      this.$refs.form.resetValidation();
    },

    closeEditProfilFCTDialog() {
      this.dialogEditProfilFCT = false;
      this.$refs.form.resetValidation();
    },

    openEditProfilFCTDialog(item) {
      this.modifProfilFCT = item;
      this.updatedProfilFCT = item;
      this.dialogEditProfilFCT = true;
    },

    openDelProfilFCTDialog(item) {
      this.modifProfilFCT = item;
      this.dialogDelProfilFCT = true;
    },

    async deleteProfilFCTConfirm() {
      this.statusCode = await delProfilFCTAPI(this.modifProfilFCT, this.$store.getters.access_token);
      const index = this.profilsFCT.indexOf(this.modifProfilFCT);
      if (index !== -1) {
        this.profilsFCT.splice(index, 1);
      }
      this.dialogDelProfilFCT = false;
    },

    closeDelProfilFCT() {
      this.dialogDelProfilFCT = false;
    },

    async refreshdata() {
      this.$store.commit("SET_CURRENT_CHAMPS", await getModifChampsAPI());
      this.profilsFCT = this.$store.getters.champs.profilsFCT;
    },
  },
};
</script>
