import axiosClient from "./customAxios";

// Appel API de la liste de recruteurs
export async function getRecruteurs() {

  return axiosClient
    .get(process.env.VUE_APP_ROOT_API + `recruteurs`)
    .then((res) => {
      return res.data.body;
    })
    .catch((e) => {
      //console.log("erreur " + e);
    });
}


// Ajouter/modifier un recruteur en BDD via API
export async function addRecruteur(Lerecruteur) {
  var recruteurJSON = JSON.stringify(Lerecruteur);
  return axiosClient.post(
    process.env.VUE_APP_ROOT_API + "recruteurs",
    recruteurJSON
  )
    .then(function (response) {
      return response;
    })
    .catch(function (erreur) {
      //console.log({ADDREC: erreur});
    });
}

// Delete un recruteur en fonction de son ID
export async function deleteRecruteur(idrecruteur) {
  return axiosClient.delete(`${process.env.VUE_APP_ROOT_API}/recruteurs/${idrecruteur}`)
}



