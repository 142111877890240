import axiosClient from "../../customAxios";

export async function addSexeAPI(sexe) {
  const SexeJSON = { new_sexe: sexe };

  //console.log('sexe :', SexeJSON);
  
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      SexeJSON
    );
    // Traitez la réponse de l'API selon vos besoins
    //console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}

