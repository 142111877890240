import axiosClient from "../../customAxios";

//Ajouter/modifier la disponibilité en BDD via API
export async function addDisponibiliteAPI(disponibilite) {

  var disponibiliteJSON = JSON.parse(disponibilite);
  return axiosClient.post(
    process.env.VUE_APP_ROOT_API + "admin/disponibilite",
    disponibiliteJSON
  )
    .then(function (response) {
      //
      //console.log('Dispo',response);
      return response;
    })
    .catch(function (erreur) {
      //Affichage de l'erreur
      //console.log(erreur);
    });
}
