<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addStatut"> Ajouter Statut </v-btn>

    <v-dialog v-model="dialogAddStatut" max-width="700px" @click:outside="closeEditStatut()">
      <v-card>
        <v-card-title>
          <span class="headline">Statut</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifStatut" label="statut*" :rules="[(v) => !!v || 'Le statut est obligatoire']"
                    required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditStatut">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditStatut">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogEditStatut" max-width="700px" @click:outside="closeEditStatut">
      <v-card>
        <v-card-title>
          <span class="headline">Statut</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedStatut" label="Statut*"
                    :rules="[(v) => !!v || 'Le statut est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditStatut">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editStatut">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelStatut" max-width="540px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce statut ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelStatut">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteStatutConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_statut" :items="$store.getters.champs.statuts" :items-per-page="5" class="elevation-1"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditStatutDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delStatut(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addStatutAPI } from "../../api/admin/statut/addstatuts";
import { updatestatutAPI } from "../../api/admin/statut/putstatuts";


import { delStatutAPI } from "../../api/admin/statut/delstatuts";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_statut: [],
    dialogAddStatut: false,
    dialogDelStatut: false,
    valid: true,
    item: [],
    editedItem: [],
    modifStatut: [],
    statusCode: 0,
    statusList: [],
    dialogEditStatut: false,
    updatedStatut: "",
  }),
  created() {
    this.initialize();
    this.statusList = this.$store.getters.champs.statuts;

  },
  methods: {
    initialize() {
      this.headers_statut = [
        {
          text: "Statuts",
          value: "statut",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addStatut() {
      this.dialogAddStatut = true;
      this.modifStatut = "";
    },
    async saveEditStatut() {
      await addStatutAPI(this.modifStatut);
      this.closeEditStatut();
      this.statusList.push(this.modifStatut);
    },


    async editStatut() {
      const data = {
        body: JSON.stringify({
          statut: this.modifStatut,
          updated_statut: this.updatedStatut
        })
      }

      await updatestatutAPI(data);

      // Supprimer l'ancien statut de la liste
      const index = this.statusList.indexOf(this.modifStatut);
      if (index !== -1) {
        this.statusList.splice(index, 1);
      }

      // Ajouter le statut mis à jour dans la liste
      this.statusList.push(this.updatedStatut);

      this.closeEditStatut(); // Fermer le dialogue ici
    },

    openEditStatutDialog(item) {
      this.modifStatut = item;
      this.updatedStatut = item;
      this.dialogEditStatut = true;
    },
    closeEditStatut() {
      this.dialogAddStatut = false;
      this.dialogEditStatut = false
      this.$refs.form.resetValidation();
    },
    delStatut(item) {
      this.modifStatut = item;
      this.dialogDelStatut = true;
    },
    deleteStatutConfirm() {
      delStatutAPI(this.modifStatut)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Statut supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.statusList.indexOf(this.modifStatut);
          if (index > -1) {
            this.statusList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelStatut();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },
    closeDelStatut() {
      this.dialogDelStatut = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
