import axiosClient from "../../customAxios";

// Ajouter/modifier un nouveau statut en BDD via API
export async function addPoleAPI(pole) {
  const poleJSON = { new_pole: pole };

  //console.log('POLE :', poleJSON);
  
  try {
    const response = await axiosClient.post(
      `${process.env.VUE_APP_ROOT_API}champs/champ`,
      poleJSON
    );

    //console.log('Response:', response);
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error; 
  }
}
