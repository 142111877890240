import axios from "axios";
import axiosClient from "../../customAxios";

//Delete un type d'école en fonction de son ID
export async function delTypeEcoleAPI(Tecole, token) {
  const options = {
    headers: {
      // 'Authorization': token.token_type + ' ' + token.access_token,
      'Content-Type': 'application/json'
    },
    data: {
      body: JSON.stringify({ "type_ecole": Tecole })
    }
    
  };

  try {
    const response = await axiosClient.delete(
      process.env.VUE_APP_ROOT_API + "champs/champ/" ,
      options
    );
    // const response = await axios.delete(
    //   process.env.VUE_APP_ROOT_API + "champs/champ/" ,
    //   options
    // );
    
    //console.log('____RESP_____', response);
    return response;
  } catch (error) {
    //console.log(error);
    return error;
  }
}

