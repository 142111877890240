<template>
  <v-data-table dense :headers="recruteurHeaders" :items="filterRecruteurs" :search="search" item-key="id_recruteur"
    :footer-props="{
      'items-per-page-options': [20, 30, 40, 50, -1],
    }" :items-per-page="30" class="elevation-1" multi-sort>
    <!--Filtres sur les champs -->
    <template v-for="(col, index) in filters" v-slot:[`header.${index}`]="{ header }">
      {{ header.text }}
      <v-menu :key="index" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small :color="filters[header.value].length ? 'red' : ''">
              mdi-filter-variant
            </v-icon>
          </v-btn>
        </template>
        <div style="background-color: white; width: 280px">
          <v-list>
            <v-list-item>
              <div v-if="filters.hasOwnProperty(header.value)">
                <v-autocomplete multiple dense clearable chips small-chips color="light-blue lighten-3"
                  :items="columnValueList(header.value)" append-icon="mdi-filter" v-model="filters[header.value]" :label="filters[header.value] ? `filtrer par: ${header.text}` : ''
                    " hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip small class="caption" v-if="index < 5">
                      <span>
                        {{ item }}
                      </span>
                    </v-chip>
                    <span v-if="index === 5" class="grey--text caption">
                      (+{{ filters[header.value].length - 5 }} others)
                    </span>
                  </template>
                </v-autocomplete>
              </div>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </template>
    <!--Barre de recherche -->
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher" single-line hide-details clearable
          background-color="#f4f4f4" class="rounded-pill" dense outlined rounded></v-text-field>
        <EditRecruteur ref="editrecruteur" />
        <DelRecruteur ref="delrecruteur" />
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="$refs.editrecruteur.modifRecruteur(item)">
        mdi-pencil
      </v-icon>
      <v-icon small class="mr-2" @click="$refs.delrecruteur.delRecruteur(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import EditRecruteur from "../form/EditRecruteur";
import DelRecruteur from "../form/DelRecruteur";
import { getRecruteurs } from "../../api/recruteurs";
import { recruteurHeaders } from "../../TabHeaders/recruteurHeaders.js";
export default {
  components: {
    EditRecruteur,
    DelRecruteur,
  },
  data: () => ({
    item: {}, //Variable de stockage de l'item du tableau
    search: "", //Variable de stockage de la recherche
    recruteurHeaders: [], //Lien entre les noms de colonnes et les données
    filters: { poste: [], uo: [], pole: [] }, //Filtres
  }),

  created() {
    this.$store.commit("SET_TITLE", "Recruteur"); //Titre de la page
    this.recruteurHeaders = recruteurHeaders;
    this.refreshDataAPI(); //Appel API
  },

  computed: {
    //Filtres des recruteurs sur chaques champs
    filterRecruteurs() {
      return this.$store.getters.recruteurs.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
  },

  methods: {
    //Appel API pour les candidats et les champs
    async refreshDataAPI() {
      //Appel API des champs
      this.$store.commit("SET_CURRENT_RECRUTEURS", await getRecruteurs());
    },
    //Recupere la liste des filtres
    columnValueList(val) {
      return this.$store.getters.recruteurs.map((d) => d[val]);
    },
  },
};
</script>
