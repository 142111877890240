export const ecoleHeaders = [
  {
    text: "Nom ecole",
    value: "nom_ecole",
    sortable: false,
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    sortable: false,
  },
  {
    text: "rang ecole",
    value: "rang_ecole",
    sortable: false,
  },
  {
    text: "Cible",
    value: "cible",
    sortable: false,
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
  },
];
