<template>
  <v-snackbar v-model="snackbar" :timeout="2000">
    Impossible de supprimer ce {{ nom }}, il est utilisé.

    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    nom: "",
  }),
  methods: {
    activate(nom) {
      this.nom = nom;
      this.snackbar = true;
    },
  },
};
</script>