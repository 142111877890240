import axiosClient from "../../customAxios";

export async function addTypeEcoleAPI(type_ecole) {
  const data = { new_type_ecole: type_ecole };


try {
  const response = await axiosClient.post(
    process.env.VUE_APP_ROOT_API + `champs/champ`,
    data
  );
  // const response = await axios.put(
  //   process.env.VUE_APP_ROOT_API + `champs/champ`,
  //   data,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   }
  // );
  // Traitez la réponse de l'API selon vos besoins
  //console.log(response.data);
} catch (error) {
  // Gérez les erreurs de l'appel API
  console.error(error);
}
}