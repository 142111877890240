export const recruteurHeaders = [
  {
    text: "Mail",
    value: "mail",
    sortable: false,
  },
  {
    text: "Nom",
    value: "nom",
    sortable: false,
  },
  {
    text: "Prenom",
    value: "prenom",
    sortable: false,
  },
  {
    text: "Trigramme",
    value: "trigramme",
    sortable: false,
  },
  {
    text: "Poste",
    value: "poste",
    sortable: false,
  },
  {
    text: "UO",
    value: "uo",
    sortable: false,
  },
  {
    text: "Pole",
    value: "pole",
    sortable: false,
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
  },
];
