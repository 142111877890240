import axiosClient from "../../customAxios";

//Delete un pole en fonction de son ID
export async function delStatutAPI(statut) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      body: JSON.stringify({ "statut": statut })
    }
    
  };

  try {
    const response = await axiosClient.delete(
      process.env.VUE_APP_ROOT_API + "champs/champ/" ,
      options
    );
    
    //console.log('____RESP_____', response);
    return response;
  } catch (error) {
    //console.log(error);
    return error;
  }
}
