<template>
  <div>
    <!--Supprimer une ecole -->
    <v-dialog v-model="booldialog" max-width="570px">
      <v-card>
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer cette école ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Supprimer</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="red" :timeout="5000">
      Impossible de supprimer cette école, des candidats y sont.
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { deleleteEcole, getEcoles } from "../../api/ecoles";
export default {
  data: () => ({
    booldialog: false,
    ecole: {},
    statusCode: 0,
    snackbar: false,
  }),
  methods: {
    //Fermeture du dialogue de suppression d'un candidat
    delEcole(Lecole) {
      this.booldialog = true;
      this.ecole = Lecole;
    },
    async deleteItemConfirm() {
      await deleleteEcole(this.ecole.id_ecole).then((res)=>{
        this.statusCode = res.status
      }).catch((err)=>{
        this.statusCode = err.response.status;
      });
      if (this.statusCode === 403) {
        this.snackbar = true;
      }
      this.booldialog = false;
      this.$store.commit("SET_CURRENT_ECOLES", await getEcoles());
    },
    //Fermeture du dialogue de suppression d'un candidat
    closeDelete() {
      this.booldialog = false;
    },
  },
};
</script>
