import axiosClient from "../../customAxios";

//Delete une disponibilité en fonction de son ID
export async function delDisponibiliteAPI(id) {
  id = { id_disponibilite: id };
  var idJSON = JSON.stringify(id); //Transformation d'un obj JS en JSON
  return axiosClient.delete(
    process.env.VUE_APP_ROOT_API + "admin/disponibilite",
    {
      data: idJSON,
    })
    .then(function (response) {
      return response; //On renvoie les données
    })
    .catch(function (erreur) {
      //Affichage de l'erreur
      //console.log(erreur);
    });
}
