<template>
  <v-card class="ma-2">
    <v-tabs background-color="#1b6a9a" center-active dark>
      <v-tab>Pôle</v-tab>
      <v-tab>Statut</v-tab>
      <v-tab>Type d'école</v-tab>
      <v-tab>Sexe</v-tab>
      <v-tab>Rang d'école</v-tab>
      <v-tab>Emploi</v-tab>
      <v-tab>Desistement </v-tab>
      <v-tab>Source Chasse</v-tab>
      <v-tab>Origine</v-tab>
      <v-tab>Emploi FCT</v-tab>
      <v-tab>TAC</v-tab>
      <v-tab>Ecole</v-tab>
      <v-tab>Recruteur</v-tab>
      <v-tab>Responsable offre</v-tab>
      <v-tab-item>
        <v-card flat>
          <TabPole />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabStatut />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabTypeEcole />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabSexe />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabRangEcole />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabProfil />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabDesistement />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabSourceChasse />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabOrigine />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabProfilFCT />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabTAC />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabEcole />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabRecruteur />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <TabRespOffre />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import TabProfil from "../components/tabAdmin/TabProfil";
import TabProfilFCT from "../components/tabAdmin/TabProfilFCT";
import TabPole from "../components/tabAdmin/TabPole";
import TabStatut from "../components/tabAdmin/TabStatut";
import TabSourceChasse from "../components/tabAdmin/TabSourceChasse";
import TabMotifDesistement from "../components/tabAdmin/TabMotifDesistement";
import TabTypeEcole from "../components/tabAdmin/TabTypeEcole";
import TabDesistement from "../components/tabAdmin/TabDesistement";
import TabDisponibilite from "../components/tabAdmin/TabDisponibilite";
import TabSexe from "../components/tabAdmin/TabSexe";
import TabRespOffre from "../components/tabAdmin/TabRespOffre.vue";
import TabRangEcole from "../components/tabAdmin/TabRangEcole";
import TabOrigine from "../components/tabAdmin/TabOrigine";
import TabTAC from "../components/tabAdmin/TabTAC";
import TabEcole from "../components/tabAdmin/TabEcole";
import TabRecruteur from "../components/tabAdmin/TabRecruteur";
import { getModifChampsAPI } from "../api/admin/getchamps";
export default {
  components: {
    TabProfil,
    TabProfilFCT,
    TabPole,
    TabStatut,
    TabSourceChasse,
    TabMotifDesistement,
    TabTypeEcole,
    TabDesistement,
    TabDisponibilite,
    TabSexe,
    TabRespOffre,
    TabRangEcole,
    TabOrigine,
    TabTAC,
    TabEcole,
    TabRecruteur
  },
  data: () => ({}),
  created() {
    this.$store.state.title = "Administration"; //Titre de la page
    this.refreshDataAPI(); //Appel API
  },
  methods: {
    async refreshDataAPI() {
      //Appel API de la liste des champs
      this.$store.commit("SET_CURRENT_CHAMPS", await getModifChampsAPI());
    },
  },
};
</script>
