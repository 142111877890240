import axiosClient from "../../customAxios";

//Ajouter/modifier un nv sexe en BDD via API
export async function addTACAPI(tac) {

  var tacJSON  = { new_trigramme: tac }
  //console.log('TAC :', tacJSON);
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      tacJSON
    );
    //console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}