<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addTAC"> Ajouter TAC </v-btn>

    <v-dialog v-model="dialogAddTAC" max-width="700px" @click:outside="closeEditTAC()">
      <v-card>
        <v-card-title>
          <span class="headline">TAC</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifTAC" label="Trigramme*"
                    :rules="[(v) => !!v || 'Le trigramme est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditTAC">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditTAC">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditTAC" max-width="700px" @click:outside="closeEditTAC">
      <v-card>
        <v-card-title>
          <span class="headline">TAC</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedTAC" label="Trigramme*"
                    :rules="[(v) => !!v || 'Le trigramme est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditTAC">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editTAC">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelTAC" max-width="590px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce trigramme ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelTAC">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteTACConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_TAC" :items="$store.getters.champs.TAC" :items-per-page="5" class="elevation-1"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditTAC(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delTAC(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import { addTACAPI } from "../../api/admin/tac/addtac";
import { delTACAPI } from "../../api/admin/tac/deltac";
import { updatetacAPI } from "../../api/admin/tac/puttac";


import { getModifChampsAPI } from "../../api/admin/getchamps";
import DelSnackbar from "../snackbar/DelSnackbar.vue";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_TAC: [],
    dialogAddTAC: false,
    dialogDelTAC: false,
    dialogEditTAC: false,
    valid: true,
    item: [],
    editedItem: [],
    modifTAC: '',
    updatedTAC: "",
    statusCode: 0,
    tacList: [],
  }),
  created() {
    this.initialize();
    this.tacList = this.$store.getters.champs.TAC;

  },
  methods: {
    initialize() {
      this.headers_TAC = [
        {
          text: "trigrammes",
          value: "trigramme",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addTAC() {
      this.dialogAddTAC = true;
      this.modifTAC = "";
    },
    async saveEditTAC() {
      await addTACAPI(this.modifTAC);
      this.closeEditTAC();
      this.tacList.push(this.modifTAC);
    },
    async editTAC() {
      const data = {
        body: JSON.stringify({
          trigramme: this.modifTAC,
          updated_TAC: this.updatedTAC,
        }),
      };

      // Appel de l'API pour mettre à jour le trigramme
      await updatetacAPI(data);

      // Supprimer l'ancien trigramme de la liste
      const index = this.tacList.indexOf(this.modifTAC);
      if (index !== -1) {
        this.tacList.splice(index, 1);
      }

      // Ajouter le trigramme mis à jour dans la liste
      this.tacList.push(this.updatedTAC);

      this.closeEditTAC(); // Fermer le dialogue ici
    },

    openEditTAC(item) {
      this.modifTAC = item;
      this.updatedTAC = item;
      this.dialogEditTAC = true;
    },

    closeEditTAC() {
      this.dialogAddTAC = false;
      this.dialogEditTAC = false;

      this.$refs.form.resetValidation();
    },
    delTAC(item) {
      this.modifTAC = item;
      this.dialogDelTAC = true;
    },

    deleteTACConfirm() {
      delTACAPI(this.modifTAC)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Statut supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.tacList.indexOf(this.modifTAC);
          if (index > -1) {
            this.tacList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelTAC();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },
    closeDelTAC() {
      this.dialogDelTAC = false;
    },
    async refreshdata() {
      this.$store.commit("SET_CURRENT_CHAMPS", await getModifChampsAPI());
    },
  },
};
</script>
