<template>
  <div>
    <!--Supprimer un recruteur -->
    <v-dialog v-model="booldialog" max-width="570px">
      <v-card>
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer ce recruteur ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Supprimer</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="red" :timeout="5000">
      Impossible de supprimer ce recruteur, il a des RDVs.
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { deleteRecruteur, getRecruteurs } from "../../api/recruteurs";
export default {
  data: () => ({
    booldialog: false,
    recruteur: {},
    statusCode: 0,
    snackbar: false,
  }),
  methods: {
    //Fermeture du dialogue de suppression d'un recruteur
    delRecruteur(Lerecruteur) {
      this.booldialog = true;
      this.recruteur = Lerecruteur;
    },
    async deleteItemConfirm() {
      await deleteRecruteur(this.recruteur.id_recruteur).then((res)=>{

        this.statusCode = res.status
      }).catch((err)=>{
        this.statusCode = err.response.status;
      });
      if (this.statusCode === 403) {
        this.snackbar = true;
      }
      this.booldialog = false;
      this.$store.commit("SET_CURRENT_RECRUTEURS", await getRecruteurs());
    },
    //Fermeture du dialogue de suppression d'un recruteur
    closeDelete() {
      this.booldialog = false;
    },
  },
};
</script>
