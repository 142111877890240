import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"570px"},model:{value:(_vm.booldialog),callback:function ($$v) {_vm.booldialog=$$v},expression:"booldialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Êtes-vous sûr de vouloir supprimer cette école ?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Annuler")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Supprimer")]),_c(VSpacer)],1)],1)],1),_c(VSnackbar,{attrs:{"color":"red","timeout":5000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Impossible de supprimer cette école, des candidats y sont. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }