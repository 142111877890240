import axiosClient from "../../customAxios";

export async function putRespOffreAPI(respOffre) {
  const respOffreJSON = { m_responsable_offre: respOffre };

  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + "champs/champ",
      respOffreJSON
    );
    // Traitez la réponse de l'API selon vos besoins
    console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}
