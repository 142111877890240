<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addDisponibilite">
      Ajouter Disponibilite
    </v-btn>

    <v-dialog v-model="dialogAddDisponibilite" max-width="700px" @click:outside="closeEditDisponibilite()">
      <v-card>
        <v-card-title>
          <span class="headline">Disponibilite</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifDisponibilite" label="Disponibilite*"
                    :rules="[(v) => !!v || 'La disponibilite est ']" required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDisponibilite">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditDisponibilite">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelDisponibilite" max-width="570px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer cette disponibilite
          ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelDisponibilite">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteDisponibiliteConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_disponibilite" :items="$store.getters.champs.disponibilites" :items-per-page="5"
      class="elevation-1" :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:[`item.actions`]="{ item }">
        {{ item }}
        <v-icon small class="mr-2" @click="addDisponibilite(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="delDisponibilite(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addDisponibiliteAPI } from "../../api/admin/disponibilite/adddisponibilites";
import { delDisponibiliteAPI } from "../../api/admin/disponibilite/deldisponibilites";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_disponibilite: [],
    dialogAddDisponibilite: false,
    dialogDelDisponibilite: false,
    valid: true,
    item: [],
    editedItem: [],
    modifDisponibilite: [],
    statusCode: 0,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.headers_disponibilite = [
        {
          text: "Disponibilites",
          value: "disponibilite",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addDisponibilite(item) {
      this.dialogAddDisponibilite = true;
      this.modifDisponibilite = item;
    },
    async saveEditDisponibilite() {
      await addDisponibiliteAPI(
        this.modifDisponibilite
      );
      this.closeEditDisponibilite();
      this.refreshdata();
    },
    closeEditDisponibilite() {
      this.dialogAddDisponibilite = false;
      this.$refs.form.resetValidation();
    },
    delDisponibilite(item) {
      this.modifDisponibilite = item;
      this.dialogDelDisponibilite = true;
    },
    async deleteDisponibiliteConfirm() {
      this.statusCode = await delDisponibiliteAPI(
        this.modifDisponibilite.id_disponibilite
      );
      if (this.statusCode.data.statusCode === 403) {
        this.$refs.delsnackbar.activate("Disponibilité");
      }
      this.dialogDelDisponibilite = false;
      this.refreshdata();
    },
    closeDelDisponibilite() {
      this.dialogDelDisponibilite = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
