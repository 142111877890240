<template>
  <div>
    <v-btn class="ml-2 grey lighten-3 rounded-pill" color="primary" text @click="modifEcole()"> Ajouter ecole </v-btn>
    <!--Dialogue de modification de candidat -->
    <v-dialog v-model="booldialog" max-width="700px" @click:outside="closeEdit">
      <v-card>
        <v-card-title class="d-flex justify-center py-3 mb-6 blue lighten-1 mc2i white--text">
          <span class="headline">Ecole</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="ecole.nom_ecole"
                    label="Nom*"
                    :rules="[
                      (v) => !!v || 'Le nom de l\'école est obligatoire',
                    ]"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="ecole.type_ecole"
                    :items="$store.getters.menuderoulant.types_ecole"
                    label="Type d'école*"
                    :rules="[(v) => !!v || 'Le type d\'école est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="ecole.rang_ecole"
                    :items="$store.getters.menuderoulant.rangs_ecole"
                    label="Rang de l'école*"
                    :rules="[
                      (v) => !!v || 'Le rang de l\'école est obligatoire',
                    ]"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ecole.cible"
                    label="Ecole cible"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit"> Annuler </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="saveEdit"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addEcole } from "../../api/ecoles";
import { getEcoles } from "../../api/ecoles";
import { getChamps } from "../../api/champs";

export default {
  watch: {
    async booldialog() {
      this.$store.commit("SET_CURRENT_ECOLES", await getEcoles());
    },
  },
  data: () => ({
    booldialog: false,
    ecole: {},
    valid: true,
  }),
  methods: {
    async modifEcole(currentEcole = {}) {
      this.booldialog = true;
      this.ecole = Object.assign({},currentEcole);
      this.$store.commit("SET_CURRENT_MENUD", await getChamps());
    },
    //Sauvegarde après addition/modification d'un candidat
    async saveEdit() {
      await addEcole(this.ecole);
      this.closeEdit();
    },
    async closeEdit() {
      this.booldialog = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>