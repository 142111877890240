import axiosClient from "../../customAxios";

// Supprimer un responsable d'offre en fonction de son trigramme
export async function delRespOffreAPI(trigramme_resp_offre) {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      body: JSON.stringify({ "trigramme_resp_offre": trigramme_resp_offre })
    }
  };

  try {
    const response = await axiosClient.delete(
      process.env.VUE_APP_ROOT_API + "champs/champ/",
      options
    );
    return response;
  } catch (error) {
    console.error("Erreur lors de la suppression du responsable d'offre :", error);
    throw error;
  }
}
