<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addTypeEcole"> Ajouter Type école
    </v-btn>
    <v-dialog v-model="dialogAddTypeEcole" max-width="700px" @click:outside="closeEditTypeEcole()">
      <v-card>
        <v-card-title>
          <span class="headline">Type ecole</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifTypeEcole" label="Type ecole*"
                    :rules="[(v) => !!v || 'Le Type d\'école est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditTypeEcole">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditTypeEcole">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditTypeEcole" max-width="700px" @click:outside="closeEditTypeEcole">
      <v-card>
        <v-card-title>
          <span class="headline">Type école</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedTypeEcole" label="Type école*"
                    :rules="[(v) => !!v || 'Le type d\'école est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditTypeEcole">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editTypeEcole">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelTypeEcole" max-width="610px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce type d'école ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelTypeEcole">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteTypeEcoleConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers_type_ecole" :items="typeEcoleList" class="elevation-1" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditTypeEcoleDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delTypeEcole(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addTypeEcoleAPI } from "../../api/admin/type_ecole/addtypes_ecole";
import { updateTypeEcoleAPI } from "../../api/admin/type_ecole/puttypeecole";
import { delTypeEcoleAPI } from "../../api/admin/type_ecole/deltypes_ecole";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_type_ecole: [],
    dialogAddTypeEcole: false,
    dialogDelTypeEcole: false,
    valid: true,
    item: [],
    editedItem: [],
    modifTypeEcole: [],
    statusCode: 0,
    typeEcoleList: [],
    dialogEditTypeEcole: false,
    updatedTypeEcole: "",

  }),
  created() {
    this.initialize();
    this.typeEcoleList = this.$store.getters.champs.types_ecole;

  },
  methods: {
    initialize() {
      this.headers_type_ecole = [
        {
          text: "Types d'école",
          value: "type_ecole",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addTypeEcole() {
      this.dialogAddTypeEcole = true;
      this.modifTypeEcole = "";
    },

    async saveEditTypeEcole() {
      await addTypeEcoleAPI(this.modifTypeEcole, this.$store.getters.access_token);
      this.closeEditTypeEcole();
      this.typeEcoleList.push(this.modifTypeEcole);
    },

    async editTypeEcole() {
      const data = {
        body: JSON.stringify({
          type_ecole: this.modifTypeEcole,
          updated_type_ecole: this.updatedTypeEcole
        })
      }

      await updateTypeEcoleAPI(data, this.$store.getters.access_token);

      // Supprimer l'ancien type d'école du tableau
      const index = this.typeEcoleList.indexOf(this.modifTypeEcole);
      if (index !== -1) {
        this.typeEcoleList.splice(index, 1);
      }

      // Ajouter le type d'école mis à jour dans le tableau
      this.typeEcoleList.push(this.updatedTypeEcole);

      this.closeEditTypeEcoleDialog();
    },

    closeEditTypeEcoleDialog() {
      this.dialogEditTypeEcole = false;
      this.$refs.form.resetValidation();
    },
    openEditTypeEcoleDialog(item) {
      this.modifTypeEcole = item;
      this.updatedTypeEcole = item;
      this.dialogEditTypeEcole = true;
    },

    closeEditTypeEcole() {
      this.dialogAddTypeEcole = false;
      this.$refs.form.resetValidation();
    },
    delTypeEcole(item) {
      this.modifTypeEcole = item;
      this.dialogDelTypeEcole = true;
    },

    deleteTypeEcoleConfirm() {
      delTypeEcoleAPI(this.modifTypeEcole, this.$store.getters.access_token)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Type Ecole supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.typeEcoleList.indexOf(this.modifTypeEcole);
          if (index > -1) {
            this.typeEcoleList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelTypeEcole();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },

    closeDelTypeEcole() {
      this.dialogDelTypeEcole = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
