import axiosClient from "../../customAxios";

//Ajouter/modifier un nv sexe en BDD via API
export async function addOrigineAPI(origine) {

  var origineJSON  = { new_origine: origine }
  
  //console.log('origine :', origineJSON);
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      origineJSON
    );

    //console.log(response.data);
  } catch (error) {
    console.error(error);
  }
}
