import axios from "axios";
import axiosClient from "../../customAxios";
  

  export async function updateTypeEcoleAPI(data, token) {
    // Utilisez le paramètre "data" pour envoyer le JSON à l'API
    // Utilisez le paramètre "token" pour inclure le jeton d'accès
  
    // Exemple d'appel à l'API avec axios :
    try {
      const response = await axiosClient.put(
        process.env.VUE_APP_ROOT_API + `champs/champ`,
        data
      );
      // const response = await axios.put(
      //   process.env.VUE_APP_ROOT_API + `champs/champ`,
      //   data,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`
      //     }
      //   }
      // );
      // Traitez la réponse de l'API selon vos besoins
      //console.log(response.data);
    } catch (error) {
      // Gérez les erreurs de l'appel API
      console.error(error);
    }
  }
  