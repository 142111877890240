<template>
  <div>
    <!-- Bouton pour ajouter un responsable d'offre -->
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addRespOffre"> Ajouter responsable
      d'offre </v-btn>

    <!-- Dialog pour ajouter ou modifier un responsable d'offre -->
    <v-dialog v-model="dialogAddRespOffre" max-width="700px" @click:outside="closeAddRespOffre">
      <v-card>
        <v-card-title>
          <span class="headline">{{ editMode ? 'Modifier' : 'Ajouter' }} un responsable d'offre</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <!-- Champs pour saisir les détails du responsable d'offre -->
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="modifRespOffre.trigramme_resp_offre" label="Trigramme*" required outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="modifRespOffre.first_name" label="Prénom*" required outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="modifRespOffre.last_name" label="Nom*" required outlined dense></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Boutons pour annuler ou sauvegarder l'ajout ou la modification -->
          <v-btn color="blue darken-1" text @click="closeAddRespOffre">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveRespOffre">
            {{ editMode ? 'Modifier' : 'Ajouter' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog pour la confirmation de suppression -->
    <v-dialog v-model="dialogDelRespOffre" max-width="530px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce responsable d'offre ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelRespOffre">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteRespOffreConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Tableau pour afficher les responsables d'offre -->
    <v-data-table :headers="headers_respoffre" :items="respOffreList" :items-per-page="5" class="elevation-1"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item.trigramme_resp_offre }}</td>
          <td class="item-column">{{ item.first_name }}</td>
          <td class="item-column">{{ item.last_name }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditRespOffre(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delRespOffre(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addRespOffreAPI } from "../../api/admin/resp_offre/addrespoffre";
import { putRespOffreAPI } from "../../api/admin/resp_offre/putrespoffre";
import { delRespOffreAPI } from "../../api/admin/resp_offre/delrespoffre";
import { getModifChampsAPI } from "../../api/admin/getchamps";

export default {
  components: { DelSnackbar },
  data() {
    return {
      headers_respoffre: [
        {
          text: "Trigramme",
          value: "trigramme_resp_offre",
        },
        {
          text: "Nom",
          value: "first_name",
        },
        {
          text: "Prénom",
          value: "last_name",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      dialogDelRespOffre: false,
      dialogAddRespOffre: false,
      modifRespOffre: {
        trigramme_resp_offre: "",
        first_name: "",
        last_name: "",
      },
      valid: false,
      respOffreList: [],
      editMode: false,
      editingItem: null,
    };
  },
  created() {
    // Initialisation des données
    this.respOffreList = this.$store.getters.champs.resp_offre;
  },
  methods: {
    openEditRespOffre(item) {
      this.editingItem = item;
      this.modifRespOffre = { ...item };
      this.dialogAddRespOffre = true;
      this.editMode = true;
    },
    delRespOffre(respOffre) {
      this.modifRespOffre = respOffre;
      this.dialogDelRespOffre = true;
    },
    deleteRespOffreConfirm() {
  delRespOffreAPI(this.modifRespOffre.trigramme_resp_offre)
    .then(response => {
      // Suppression réussie
      this.closeDelRespOffre();
    })
    .catch(error => {
      // Gestion de l'erreur lors de la suppression
      console.error("Erreur lors de la suppression du responsable d'offre :", error);
      this.closeDelRespOffre();
    })
    .finally(() => {
      // Retirer le responsable de la liste, que la suppression réussisse ou échoue
      const index = this.respOffreList.indexOf(this.modifRespOffre);
      if (index > -1) {
        this.respOffreList.splice(index, 1);
      }
    });
},



    closeDelRespOffre() {
      this.dialogDelRespOffre = false;
    },
    async refreshdata() {
      // Rafraîchir les données après une modification
      this.respOffreList = await getModifChampsAPI().champs.resp_offre;
    },
    addRespOffre() {
      this.modifRespOffre = {
        trigramme_resp_offre: "",
        first_name: "",
        last_name: "",
      };
      this.valid = false;
      this.editMode = false;
      this.dialogAddRespOffre = true;
    },
    closeAddRespOffre() {
      this.dialogAddRespOffre = false;
    },
    saveRespOffre() {
  if (this.editMode) {
    putRespOffreAPI(this.modifRespOffre)
      .then(response => {
        // Modification réussie
        this.closeAddRespOffre();
        // Mise à jour des données de la ligne existante
        const index = this.respOffreList.indexOf(this.editingItem);
        if (index !== -1) {
          this.$set(this.respOffreList, index, this.modifRespOffre);
        }
      })
      .catch(error => {
        // Gestion de l'erreur lors de la modification
        console.error("Erreur lors de la modification du responsable d'offre :", error);
      });
  } else {
    addRespOffreAPI(this.modifRespOffre)
      .then(response => {
        // Ajout réussi
        this.closeAddRespOffre();
        this.respOffreList.push(this.modifRespOffre); // Ajouter le nouveau responsable à la liste
      })
      .catch(error => {
        // Gestion de l'erreur lors de l'ajout
        console.error("Erreur lors de l'ajout du responsable d'offre :", error);
      });
  }
}


  },
};
</script>
