<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addSexe"> Ajouter sexe </v-btn>

    <v-dialog v-model="dialogAddSexe" max-width="700px" @click:outside="closeEditSexe()">
      <v-card>
        <v-card-title>
          <span class="headline">Sexe</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifSexe" label="Sexe*" :rules="[(v) => !!v || 'Le sexe est obligatoire']"
                    required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditSexe">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditSexe">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditSexe" max-width="700px" @click:outside="closeEditSexe">
      <v-card>
        <v-card-title>
          <span class="headline">Sexe</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedSexe" label="Sexe*" :rules="[(v) => !!v || 'Le sexe est obligatoire']"
                    required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditSexe">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editSexe">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelSexe" max-width="530px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce sexe ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelSexe">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteSexeConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_sexe" :items="$store.getters.champs.sexe" :items-per-page="5" class="elevation-1"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditSexe(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delSexe(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addSexeAPI } from "../../api/admin/sexe/addsexes";
import { updateSexeAPI } from "../../api/admin/sexe/putsexes";
import { delSexeAPI } from "../../api/admin/sexe/delsexes";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_sexe: [],
    dialogAddSexe: false,
    dialogDelSexe: false,
    valid: true,
    item: [],
    editedItem: [],
    modifSexe: [],
    statusCode: 0,
    sexeList: [],
    dialogEditSexe: false,
    updatedSexe: "",

  }),
  created() {
    this.initialize();
    this.sexeList = this.$store.getters.champs.sexe;

  },
  methods: {
    initialize() {
      this.headers_sexe = [
        {
          text: "Sexes",
          value: "sexe",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addSexe() {
      this.dialogAddSexe = true;
      this.modifSexe = "";
    },
    async saveEditSexe() {
      await addSexeAPI(this.modifSexe);
      this.closeEditSexe();
      this.sexeList.push(this.modifSexe);
    },
    async editSexe() {
      const data = {
        body: JSON.stringify({
          sexe: this.modifSexe,
          updated_sexe: this.updatedSexe,
        }),
      };

      // Appel de l'API pour mettre à jour le sexe
      await updateSexeAPI(data);

      // Supprimer l'ancien sexe de la liste
      const index = this.sexeList.indexOf(this.modifSexe);
      if (index !== -1) {
        this.sexeList.splice(index, 1);
      }

      // Ajouter le sexe mis à jour dans la liste
      this.sexeList.push(this.updatedSexe);

      this.closeEditSexe(); // Fermer le dialogue ici
    },
    openEditSexe(item) {
      this.modifSexe = item;
      this.updatedSexe = item;
      this.dialogEditSexe = true;
    },


    closeEditSexe() {
      this.dialogAddSexe = false;
      this.dialogEditSexe = false
      this.$refs.form.resetValidation();
    },
    delSexe(item) {
      this.modifSexe = item;
      this.dialogDelSexe = true;
    },

    deleteSexeConfirm() {
      delSexeAPI(this.modifSexe)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Statut supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.sexeList.indexOf(this.modifSexe);
          if (index > -1) {
            this.sexeList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelSexe();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },
    closeDelSexe() {
      this.dialogDelSexe = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
