import axiosClient from "../../customAxios";

export async function addSourceChasseAPI(source_chasse) {

  var source_chasseJSON  = { new_source_chasse: source_chasse}
  //console.log('Source chasse :', source_chasseJSON);
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      source_chasseJSON
    );

    //console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}