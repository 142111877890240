<template>
  <div>
    <v-btn class="ml-2 grey lighten-3 rounded-pill" color="primary" text @click="modifRecruteur()">
      Ajouter recruteur
    </v-btn>
    <!--Dialogue de modification de candidat -->
    <v-dialog v-model="booldialog" max-width="700px" @click:outside="closeEdit">
      <v-card>
        <v-card-title class="d-flex justify-center py-3 mb-6 blue lighten-1 mc2i white--text">
          <span class="headline">Recruteur</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="recruteur.mail"
                    label="Mail*"
                    :rules="[(v) => !!v || 'L\'adresse mail est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="recruteur.nom"
                    label="Nom*"
                    :rules="[(v) => !!v || 'Le nom est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="recruteur.prenom"
                    label="Prenom*"
                    :rules="[(v) => !!v || 'Le prenom est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="recruteur.trigramme"
                    label="Trigramme*"
                    :rules="[(v) => !!v || 'Le trigramme est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="recruteur.poste"
                    label="Poste*"
                    :rules="[(v) => !!v || 'Le poste est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="recruteur.uo"
                    label="UO*"
                    :rules="[(v) => !!v || 'L\'UO est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="recruteur.pole"
                    :items="$store.getters.menuderoulant.poles"
                    label="Pôle*"
                    :rules="[(v) => !!v || 'Le pôle est obligatoire']"
                    required
                    outlined
                    dense
                    rounded
                    background-color="grey lighten-4"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit"> Annuler </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="saveEdit"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addRecruteur } from "../../api/recruteurs";
import { getRecruteurs } from "../../api/recruteurs";
import { getChamps } from "../../api/champs";

export default {
  watch: {
    async booldialog() {
      this.$store.commit("SET_CURRENT_RECRUTEURS", await getRecruteurs());
    },
  },
  data: () => ({
    booldialog: false,
    recruteur: {},
    valid: true,
    emailRules: [
      (v) => !!v || "L'adresse mail est obligatoire",
      (v) => /.+@.+/.test(v) || "L'adresse mail doit-être valide",
    ],
  }),
  methods: {
    async modifRecruteur(currentRecruteur = {}) {
      this.booldialog = true;
      this.recruteur = Object.assign({},currentRecruteur);
      this.$store.commit("SET_CURRENT_MENUD", await getChamps());
    },
    //Sauvegarde après addition/modification d'un candidat
    async saveEdit() {
      await addRecruteur(this.recruteur);
      this.closeEdit();
    },
    closeEdit() {
      this.booldialog = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>