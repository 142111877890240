import axiosClient from "../../customAxios";

//Ajouter/modifier un nv sexe en BDD via API
export async function addProfilFCTAPI(profilFCT) {

  var profilFCTJSON  = { new_profilFCT: profilFCT }
  
  //console.log('ProfilFCT :', profilFCTJSON);
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      profilFCTJSON
    );

    // Traitez la réponse de l'API selon vos besoins
    //console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}
