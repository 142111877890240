import axiosClient from "../../customAxios";

//Delete un motif du desistement en fonction de son ID
export async function delMotifDesistementAPI(id) {
  id = { id_motif_desistement: id };
  var idJSON = JSON.stringify(id);
  return axiosClient.delete(
    process.env.VUE_APP_ROOT_API + "admin/motif-desistement",
    {
      data: idJSON,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (erreur) {
      //Affichage de l'erreur
      //console.log(erreur);
    });
}
