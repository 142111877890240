<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="openAddPoleDialog">Ajouter Pôle</v-btn>

    <v-dialog v-model="dialogAddPole" max-width="700px" @click:outside="closeAddPoleDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Pôle</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifPole" label="Pôle*" :rules="[(v) => !!v || 'Le pole est obligatoire']"
                    required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddPoleDialog">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditPole">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditPole" max-width="700px" @click:outside="closeEditPoleDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Pôle</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedPole" label="Pôle*" :rules="[(v) => !!v || 'Le pole est obligatoire']"
                    required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddPoleDialog">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editpole">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelPole" max-width="525px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce pôle ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelPole">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deletePoleConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_pole" :items="poles" class="elevation-1" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditPoleDialog(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="openDelPoleDialog(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import { addPoleAPI } from "../../api/admin/pole/addpoles";
import { updatepoleAPI } from "../../api/admin/pole/putpoles";
import { delPoleAPI } from "../../api/admin/pole/delpoles";
import { getModifChampsAPI } from "../../api/admin/getchamps";
import DelSnackbar from "../snackbar/DelSnackbar.vue";

export default {
  components: { DelSnackbar },
  data() {
    return {
      headers_pole: [
        {
          text: "Pôles",
          value: "pole",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      dialogAddPole: false,
      dialogDelPole: false,
      valid: true,
      modifPole: "",
      statusCode: 0,
      poles: [],
      dialogEditPole: false,
      updatedPole: "",
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.poles = this.$store.getters.champs.poles;
    },

    openAddPoleDialog() {
      this.modifPole = "";
      this.dialogAddPole = true;
    },

    async saveEditPole() {
      await addPoleAPI(this.modifPole);
      this.closeAddPoleDialog();
      this.poles.push(this.modifPole);
    },

    async editpole() {
      const data = {
        body: JSON.stringify({
          pole: this.modifPole,
          updated_pole: this.updatedPole
        })
      }

      await updatepoleAPI(data);

      // Supprimer l'ancien pôle du tableau
      const index = this.poles.indexOf(this.modifPole);
      if (index !== -1) {
        this.poles.splice(index, 1);
      }

      // Ajouter le pôle mis à jour dans le tableau
      this.poles.push(this.updatedPole);

      this.closeEditPoleDialog();
    },

    closeAddPoleDialog() {
      this.dialogAddPole = false;
      this.$refs.form.resetValidation();
    },
    closeEditPoleDialog() {
      this.dialogEditPole = false;
      this.$refs.form.resetValidation();
    },

    openEditPoleDialog(item) {
      this.modifPole = item;
      this.updatedPole = item;
      this.dialogEditPole = true;
    },

    openDelPoleDialog(item) {
      this.modifPole = item;
      this.dialogDelPole = true;
    },

    async deletePoleConfirm() {
      this.statusCode = await delPoleAPI(this.modifPole);
      const index = this.poles.indexOf(this.modifPole);
      if (index !== -1) {
        this.poles.splice(index, 1);
      }
      this.dialogDelPole = false;
    },

    closeDelPole() {
      this.dialogDelPole = false;
    },

    async refreshdata() {
      this.$store.commit("SET_CURRENT_CHAMPS", await getModifChampsAPI());
      this.poles = this.$store.getters.champs.poles;
    },
  },
};
</script>
