import axiosClient from "../../customAxios";

//Ajouter/modifier un nv sexe en BDD via API
export async function addProfilAPI(profil) {

  const profilJSON  = { new_profil: profil }
  
  
  //console.log('Profil :', profilJSON);
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      profilJSON
    );

    // Traitez la réponse de l'API selon vos besoins
    //console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}