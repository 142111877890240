import axiosClient from "../../customAxios";


// Ajouter/modifier un nouveau statut en BDD via API
export async function addStatutAPI(statut) {
  const statutJSON = { new_statut: statut };

  //console.log('Statut :', statutJSON);
  
  try {
    const response = await axiosClient.post(
      `${process.env.VUE_APP_ROOT_API}champs/champ`,
      statutJSON
    );

    //console.log('Response:', response);
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error; 
  }
}
