import axiosClient from "../../customAxios";

//Ajouter/modifier un motif du desistement en BDD via API
export async function addMotifDesistementAPI(motif_desistement) {

  var motif_desistementJSON = JSON.stringify(motif_desistement);
  return axiosClient.post(
    process.env.VUE_APP_ROOT_API + "admin/motif-desistement",
    motif_desistementJSON)
    .then(function (response) {
      return response;
    })
    .catch(function (erreur) {
      //Affichage de l'erreur
      //console.log(erreur);
    });
}

// import axiosClient from "../../customAxios";

// //Ajouter/modifier un motif du desistement en BDD via API
// export async function addMotifDesistementAPI(motif_desistement) {

//   var motif_desistementJSON = { new_motif_desistement: motif_desistement}
//   try {
//     const response = await axiosClient.put(
//       process.env.VUE_APP_ROOT_API + `champs/champ`,
//       motif_desistementJSON
//     );

//     // Traitez la réponse de l'API selon vos besoins
//     console.log(response.data);
//   } catch (error) {
//     // Gérez les erreurs de l'appel API
//     console.error(error);
//   }
// }
