<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addRangEcole"> Ajouter Rang ecole
    </v-btn>
    <v-dialog v-model="dialogAddRangEcole" max-width="700px" @click:outside="closeEditRangEcole()">
      <v-card>
        <v-card-title>
          <span class="headline">Rang école</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifRangEcole" label="Rang ecole*"
                    :rules="[(v) => !!v || 'Le rang ecole est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditRangEcole">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditRangEcole">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditRangEcole" max-width="700px" @click:outside="closeEditRangEcole">
      <v-card>
        <v-card-title>
          <span class="headline">Rang école</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="updatedRangEcole" label="Rang école*"
                    :rules="[(v) => !!v || 'Le rang école est obligatoire']" required outlined dense rounded
                    background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditRangEcole">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="editRangEcole">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelRangEcole" max-width="610px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ce rang d'école ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelRangEcole">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteRangEcoleConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_rang_ecole" :items="$store.getters.champs.rangs_ecole" :items-per-page="5"
      class="elevation-1" :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="openEditRangEcole(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delRangEcole(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addRangEcoleAPI } from "../../api/admin/rang_ecole/addrangs_ecole";
import { updateRangEcoleAPI } from "../../api/admin/rang_ecole/putrangecole";
import { delRangEcoleAPI } from "../../api/admin/rang_ecole/delrangs_ecole";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_rang_ecole: [],
    dialogAddRangEcole: false,
    dialogDelRangEcole: false,
    valid: true,
    item: [],
    editedItem: [],
    modifRangEcole: [],
    statusCode: 0,
    rangList: [],
    dialogEditRangEcole: false,
    updatedRangEcole: ""
  }),
  created() {
    this.initialize();
    this.rangList = this.$store.getters.champs.rangs_ecole;

  },
  methods: {
    initialize() {
      this.headers_rang_ecole = [
        {
          text: "Rangs ecole",
          value: "rang_ecole",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addRangEcole() {
      this.dialogAddRangEcole = true;
      this.modifRangEcole = "";
    },
    async saveEditRangEcole() {
      await addRangEcoleAPI(
        this.modifRangEcole
      );
      this.closeEditRangEcole();
      this.rangList.push(this.modifRangEcole);
    },

    async editRangEcole() {
      const data = {
        body: JSON.stringify({
          rang_ecole: this.modifRangEcole,
          updated_rang_ecole: this.updatedRangEcole,
        }),
      };

      // Appel de l'API pour mettre à jour le rang d'école
      await updateRangEcoleAPI(data);

      // Supprimer l'ancien rang d'école de la liste
      const index = this.rangList.indexOf(this.modifRangEcole);
      if (index !== -1) {
        this.rangList.splice(index, 1);
      }

      // Ajouter le rang d'école mis à jour dans la liste
      this.rangList.push(this.updatedRangEcole);

      this.closeEditRangEcole(); // Fermer le dialogue ici
    },

    openEditRangEcole(item) {
      this.modifRangEcole = item;
      this.updatedRangEcole = item;
      this.dialogEditRangEcole = true;
    },


    closeEditRangEcole() {
      this.dialogAddRangEcole = false;
      this.dialogEditRangEcole = false
      this.$refs.form.resetValidation();
    },
    delRangEcole(item) {
      this.modifRangEcole = item;
      this.dialogDelRangEcole = true;
    },

    deleteRangEcoleConfirm() {
      delRangEcoleAPI(this.modifRangEcole)
        .then(response => {
          // Traitement réussi de la suppression
          //console.log("Statut supprimé avec succès");

          // Supprime l'élément du tableau statusList
          const index = this.rangList.indexOf(this.modifRangEcole);
          if (index > -1) {
            this.rangList.splice(index, 1);
          }

          // Ferme la boîte de dialogue de suppression
          this.closeDelRangEcole();

          // Actualise les données pour refléter les changements dans votre table
          this.refreshdata();
        })
        .catch(error => {
          // Gestion de l'erreur lors de la suppression
          console.error("Erreur lors de la suppression du statut :", error);
          // Ajoutez ici toute logique pour gérer l'erreur de suppression
        });
    },
    closeDelRangEcole() {
      this.dialogDelRangEcole = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
