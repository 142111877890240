import axiosClient from "../customAxios";

//Appel API de la liste des champs formatté pour l'affichage en tableau
export async function getModifChampsAPI() {

  //console.log('-- CHAMPS --');
  try {
    const res = await axiosClient.get(process.env.VUE_APP_ROOT_API + `champs`);
    //console.log('All-CHAMPS',res.data)
    //console.log('OPT',options);
    return res.data;
  } catch (e) {
    //console.log("erreur ");
  }
}

