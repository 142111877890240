import axiosClient from "../../customAxios";

  export async function updatedesistementAPI(data) {
    try {
      const response = await axiosClient.put(
        process.env.VUE_APP_ROOT_API + `champs/champ`,
        data
      );

      //console.log(response.data);
    } catch (error) {
      // Gérez les erreurs de l'appel API
      console.error(error);
    }
  }
  