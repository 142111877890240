import axiosClient from "../../customAxios";

//Ajouter/modifier un nv sexe en BDD via API
export async function addRangEcoleAPI(rang_ecole) {

  const rang_ecoleJSON = { new_rang_ecole: rang_ecole };
  //console.log('sexe :', rang_ecoleJSON);
  
  try {
    const response = await axiosClient.post(
      process.env.VUE_APP_ROOT_API + `champs/champ`,
      rang_ecoleJSON
    );
    // Traitez la réponse de l'API selon vos besoins
    //console.log(response.data);
  } catch (error) {
    // Gérez les erreurs de l'appel API
    console.error(error);
  }
}
