<template>
  <div>
    <v-btn class="ma-2 grey lighten-3 rounded-pill" color="primary" text @click="addMotifDesistement">
      Ajouter Motif désistement
    </v-btn>

    <v-dialog v-model="dialogAddMotifDesistement" max-width="700px" @click:outside="closeEditMotifDesistement()">
      <v-card>
        <v-card-title>
          <span class="headline">Motif désistement</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row wrap>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="modifMotifDesistement" label="Motif desistement*" :rules="[
                    (v) => !!v || 'Le motif du désistement est obligatoire',
                  ]" required outlined dense rounded background-color="grey lighten-4"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditMotifDesistement">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveEditMotifDesistement">
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Supprimer un candidat -->
    <v-dialog v-model="dialogDelMotifDesistement" max-width="570px">
      <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer cette Motif desistement
          ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelMotifDesistement">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteMotifDesistementConfirm">Supprimer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table :headers="headers_motif_desistement" :items="$store.getters.champs.motif_desistement"
      :items-per-page="5" class="elevation-1" :footer-props="{
        'items-per-page-options': [20, 30, 40, 50, -1],
      }" dense>
      <template v-slot:item="{ item }">
        <tr>
          <td class="item-column">{{ item }}</td>
          <td class="action-column">
            <v-icon small class="mr-2" @click="addMotifDesistement(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="delMotifDesistement(item)">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <DelSnackbar ref="delsnackbar" />
  </div>
</template>

<script>
import DelSnackbar from "../snackbar/DelSnackbar";
import { addMotifDesistementAPI } from "../../api/admin/motif_desistement/addmotifs_desistement";
import { delMotifDesistementAPI } from "../../api/admin/motif_desistement/delmotifs_desistement";
import { getModifChampsAPI } from "../../api/admin/getchamps";
export default {
  components: { DelSnackbar },
  data: () => ({
    headers_motif_desistement: [],
    dialogAddMotifDesistement: false,
    dialogDelMotifDesistement: false,
    valid: true,
    item: [],
    editedItem: [],
    modifMotifDesistement: [],
    statusCode: 0,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.headers_motif_desistement = [
        {
          text: "Motifs desistement",
          value: "motif_desistement",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
    addMotifDesistement(item) {
      this.dialogAddMotifDesistement = true;
      this.modifMotifDesistement = item;
    },
    async saveEditMotifDesistement() {
      await addMotifDesistementAPI(
        this.modifMotifDesistement
      );
      this.closeEditMotifDesistement();
      this.refreshdata();
    },
    closeEditMotifDesistement() {
      this.dialogAddMotifDesistement = false;
      this.$refs.form.resetValidation();
    },
    delMotifDesistement(item) {
      this.modifMotifDesistement = item;
      this.dialogDelMotifDesistement = true;
    },
    async deleteMotifDesistementConfirm() {
      this.statusCode = await delMotifDesistementAPI(
        this.modifMotifDesistement.id_motif_desistement
      );
      if (this.statusCode.data.statusCode === 403) {
        this.$refs.delsnackbar.activate("Motif du désistement");
      }
      this.dialogDelMotifDesistement = false;
      this.refreshdata();
    },
    closeDelMotifDesistement() {
      this.dialogDelMotifDesistement = false;
    },
    async refreshdata() {
      this.$store.commit(
        "SET_CURRENT_CHAMPS",
        await getModifChampsAPI()
      );
    },
  },
};
</script>
